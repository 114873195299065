import BaseFormatter from './base'
import { capitalize, has, hasFields } from '../../helpers'

/**
 * Format report response data for PE file details page
 */
export default class PEDetailsFormatter extends BaseFormatter {
  /**
   * Format original report data
   *
   * @param data
   * @param report
   */
  format(data: { [key: string]: any }, report: { [key: string]: any }): void {
    const result: { [key: string]: any } = {}

    const resource = this.getResource(data, 'file')
    if (!resource) return

    const fileType = (!data?.file?.type || data.file.type === 'other') ?
      this.getFileType(resource) : data.file.type
    if (fileType !== 'pe') {
      return
    }

    const peData = resource.extendedData
    this.groupOverview(resource, result)
    this.groupStatistic(peData, result)
    this.groupHeader(peData, result)
    this.groupCharacteristics(peData, result)
    this.groupImports(peData, result)
    this.groupExports(peData, result)
    this.groupPDB(peData, result)
    this.groupRichHeaders(peData, result)
    this.groupGoLangFeatures(resource?.goLangFeatures, result)
    this.copyGrouped(peData, result)
    this.copyFromResource(resource, result)

    result.iconHash = this.extractIcon(resource);
    for (const name of ['sfxParameters', 'dotnetInfo']) {
      if (peData && typeof peData[name] !== 'undefined') {
        result[name] = peData[name]
      }
    }

    if (hasFields(result)) {
      report.pe_details = { ...report.details, ...result }
      report.pe_details.overview = { ...report.details.overview, ...result.overview }
      delete report.details
    }
  }

  copyFromResource(data: { [key: string]: any }, result: { [key: string]: any }): void {
    this.copyFields(['certInfos'], data, result)
  }

  /**
   * Gather data that is shown as PE details overview
   *
   * @param data All `extrndedData` section
   * @param result
   */
  groupOverview(resource: { [key: string]: any }, result: { [key: string]: any }): void {
    const overview: { [key: string]: any } = {}
    const data = resource.extendedData
    const dieInfo = resource?.dieInfo || []

    const names = ['architecture', 'subsystemReadable']
    this.copyFields(names, data, overview)

    if (has(data, 'language')) {
      const lang = data.language

      if (typeof lang === 'string') {
        overview.language = capitalize(lang, true)
      } else if (Array.isArray(lang) && lang.length) {
        overview.language = lang
      }
    }

    if (has(data, 'dates')) {
      overview.date = data.dates.dateUtc
    }

    if (has(data, 'packers')) {
      overview.packers = data.packers.join(', ')
    }

    if (has(data, 'compilers')) {
      overview.compilers = data.compilers.join(', ')
    }

    for (const info of dieInfo) {
      if (typeof info.type === 'undefined') {
        continue
      }

      const typeKey = `${capitalize(info.type)}s (DiE)`
      if(!overview[typeKey]) {
        overview[typeKey] = this.getDieInfoDetails(dieInfo, info.type)
      }
    }

    if (has(data, 'isDigitallySigned')) {
      overview.isDigitallySigned = data.isDigitallySigned
    }

    this.copyFields(['isDotNet', 'isPacked'], data, overview)

    overview.icon = ''; // just for showing the icon before vis

    if (hasFields(overview)) {
      result.overview = overview
    }
  }

  /**
   * Get all Detect it Easy information for the given *type*
   *
   * @param dieInfo DiE data array
   * @param type DiE type
   * @returns DiE info for the given *type* separated by comma
   */
  getDieInfoDetails(dieInfo: { [key: string]: any }, type: string) : string {
    return dieInfo
      .map((item: { [key: string]: any }) =>
        item.type === type ?
        item.display_str.replace(`${type}: `, '') :
        undefined
      )
      .filter((item: string) => item !== undefined)
      .join(", ")
  }

  /**
   * Group fields that we consider as statistic fields
   *
   * @param data
   * @param result
   */
  groupStatistic(data: { [key: string]: any }, result: { [key: string]: any }): void {
    const stat: { [key: string]: any } = {}

    if (has(data, 'stats', 'totalImportedFunctions')) {
      stat.totalImportedFunctions = data.stats.totalImportedFunctions
    }

    if (has(data, 'stats', 'totalResourceAmountFromFileRatio')) {
      stat.totalResourceAmountFromFileRatio = data.stats.totalResourceAmountFromFileRatio
    }

    if (has(data, 'resources')) {
      const resourcesCount: { [key: string]: number } = {}

      data.resources.forEach((item: { [key: string]: any }) => {
        const type = item.type
        if (!has(resourcesCount, type)) {
          resourcesCount[type] = 0
        }

        resourcesCount[type]++
      })

      if (hasFields(resourcesCount)) {
        stat.resourcesCount = resourcesCount
      }
    }

    if (hasFields(stat)) {
      if (has(stat, 'error') && has(stat, 'errorReadable'))
        stat['error'] = stat['errorReadable']
      stat.stat = stat
    }
  }

  /**
   * Group header simple fields
   *
   * @param data
   * @param result
   */
  groupHeader(data: { [key: string]: any }, result: { [key: string]: any }): void {
    const header: { [key: string]: any } = {}
    const names = [
      'imageBase',
      'entrypointName',
      'entrypointEntropy',
      'entrypointVA',
      'tlsCallbacks',
      'crcInFile',
      'crcActual',
      'linkerVersionMajor',
      'linkerVersionMinor',
      'compilerFlags',
      'pointerToSymbolTable',
      'numberOfSymbols'
    ]

    this.copyFields(names, data, header)

    if (hasFields(header)) {
      if (has(header, 'error') && has(header, 'errorReadable'))
        header['error'] = header['errorReadable']
      result.header = header
    }
  }

  /**
   * Group characteristics simple fields
   *
   * @param data
   * @param result
   */
  groupCharacteristics(data: { [key: string]: any }, result: { [key: string]: any }): void {
    const characteristics: { [key: string]: any } = {}
    const names = [
      'fileCharacteristicsReadable',
      'fileCharacteristics',
      'dllCharacteristics',
      'dllCharacteristicsReadable'
    ]

    this.copyFields(names, data, characteristics)

    if (hasFields(characteristics)) {
      if (has(characteristics, 'error') && has(characteristics, 'errorReadable'))
        characteristics['error'] = characteristics['errorReadable']
      result.characteristics = characteristics
    }
  }

  /**
   * Merge some fields from importsEx to imports
   *
   * @param data
   * @param result
   */
  groupImports(data: { [key: string]: any }, result: { [key: string]: any }): void {
    if (!has(data, 'imports')) {
      return
    }

    const imports = data.imports
    const names = ['fileRva', 'suspicious', 'allMitreTechniques']

    if (has(data, 'importsEx')) {
      data.importsEx.forEach((moduleEx: { [key: string]: any }) => {
        const curImport = imports.find((item: any) => moduleEx.module.name.toLowerCase() === item.module.toLowerCase());

        moduleEx.imports.forEach((imp: { [key: string]: any }, idx: number) => {
          this.mergeImportItem(imp, curImport.imports[idx], moduleEx.module, names)
        });
      });
      result.imports = data.importsEx
      return;
    }

    result.imports = imports
  }

  groupExports(data: { [key: string]: any }, result: { [key: string]: any }): void {
    if (!has(data, 'exports') || !data.exports.length) {
      return
    }

    result.exports = data.exports
  }

  groupPDB(data: { [key: string]: any }, result: { [key: string]: any }): void {
    if (!has(data, 'pdbData') || !data.pdbData.length) {
      return
    }

    result.pdbData = data.pdbData
  }

  groupRichHeaders(data: { [key: string]: any }, result: { [key: string]: any }): void {
    const headers: { [key: string]: any } = {}
    const sources = ['richHeader', 'richHeaderEx']

    for (const source of sources) {
      if (has(data, source)) {
        Object.keys(data[source]).forEach(key => {
          headers[key] = data[source][key]
        })
      }
    }

    if (hasFields(headers)) {
      if (has(headers, 'errorReadable')) {
        headers.error = headers.errorReadable
        delete headers.errorReadable
      }

      if (Object.keys(headers).length === 1 && headers.error === 'DanS signature not found') {
        return
      }
      result.richHeader = headers
    }
  }

  /**
   * Create data for 3 tabs of GoLang info: main info, user functions, standard functions
   * @param features 
   * @param result 
   */
  groupGoLangFeatures(features: { [key: string]: any }, result: { [key: string]: any }): void {
    if (!(features && Object.keys(features).length))
      return

    const { userFunctions, stdFunctions, ...info } = features
    const { goImphash, version, buildId, buildInfo, files } = info

    result.golangInfo = { goImphash, version, buildId, buildInfo }
    result.golangUserFunctions = this.groupAndSortGolangFunctions(userFunctions)
    result.golangStdFunctions = this.groupAndSortGolangFunctions(stdFunctions)
    result.golangFiles = files
  }

  /**
   * Groups functions by packageName and sorts them by isInteresting.
   * Also sorts interesting packages.
   * @param functions
   */
  groupAndSortGolangFunctions(functions: { [key: string]: any }[] | undefined) {
    if (!functions?.length)
      return {}

    const groupedFunctions: { [key: string]: any } = {
      interestingPackages: []
    }

    // group functions by packageName and mark interesting packages (that containes an interesting function)
    functions.forEach((item: { [key: string]: any }) => {
      const packageName = item?.packageName || "Others"

      if (!groupedFunctions[packageName]) {
        groupedFunctions[packageName] = []
      }

      groupedFunctions[packageName].push({
        fullName: item.fullName,
        isInteresting: item.isInteresting
      })

      if (item.isInteresting && !groupedFunctions.interestingPackages.includes(packageName)) {
        groupedFunctions.interestingPackages.push(packageName)
      }
    })

    // sort interesting functions
    for (const packageName in groupedFunctions) {
      if (Array.isArray(groupedFunctions[packageName])) {
        groupedFunctions[packageName].sort((a: any, b: any) => b.isInteresting - a.isInteresting)
      }
    }

    // sort interesting packages
    const sortedFunctions: { [key: string]: any } = {
      interestingPackages: groupedFunctions.interestingPackages
    }
    
    groupedFunctions.interestingPackages.forEach((pkg: string) => {
      sortedFunctions[pkg] = groupedFunctions[pkg]
    })
    
    Object.keys(groupedFunctions).forEach(pkg => {
      if (!groupedFunctions.interestingPackages.includes(pkg) && pkg !== 'interestingPackages') {
        sortedFunctions[pkg] = groupedFunctions[pkg]
      }
    })

    return sortedFunctions
  }

  mergeImportItem(
    target: { [key: string]: any },
    source: { [key: string]: any },
    targetModule: { [key: string]: any },
    names: string[]
  ): void {
    names.forEach(name => {
      if (!has(source, name)) {
        return
      }

      target[name] = source[name]

      if (name !== 'allMitreTechniques') {
        return
      }

      targetModule.mitre = source[name]
    })
  }

  /**
   * Just copy data, that does not require any grouping, because it comes pregrouped
   *
   * @param data
   * @param result
   */
  copyGrouped(data: { [key: string]: any }, result: { [key: string]: any }): void {
    const names = ['sections', 'resources', 'verinfo']

    this.copyFields(names, data, result)
  }

  extractIcon(resource: { [key: string]: any }): string | null {
    if (!resource) return null;

    const resources = resource.extendedData?.resources;
    const extractedFiles = resource.extractedFiles;
    if (!resources || !extractedFiles) return null;

    // create a resource map for RT_ICON type resources
    const iconsMap = new Map();
    for (const resource of resources) {
      if (resource.type === 'RT_ICON') {
        iconsMap.set(resource.sha256, resource);
      }
    }

    // get the file of max size
    let maxID = null, sizeMax = 0;
    for (const file of extractedFiles) {
      if (!file.mediaType?.string.startsWith('image/')) continue;

      const hash = file.digests['SHA-256'];
      if (iconsMap.has(hash)) {
        if (parseInt(file.fileSize) > sizeMax) {
          sizeMax = parseInt(file.fileSize);
          maxID = hash;
        }
      }
    }

    iconsMap.clear();
    return maxID;
  }
}
